import React from 'react';
import AuthPage from './auth/AuthPage';
//import logo from './logo.svg';
//import './App.css';

function App() {
  return (
    <div>
      <AuthPage/>
    </div>
  );
}

export default App;
