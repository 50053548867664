import React from "react"
import { Container } from 'react-bootstrap';

export default function About() {
    return(
        <div style={{color:"blue"}}> 
            <Container>
                About
            </Container>        
        </div>
    );
  }